'use client'

import { ReactNode, useEffect, useRef } from "react"

type CollapseProps = {
    children: ReactNode,
    className?: string,
    id: string,
    onShow?: Function,
    onHide?: Function
    parent?: string
}

export function Collapse({
    children,
    id,
    className = '',
    parent = '',
    onShow = () => {},
    onHide = () => {}
}: CollapseProps) {

   useEffect(() => {
        
        const load = async () => {
            const bootstrap = (await import('bootstrap/dist/js/bootstrap')).default;
            new bootstrap.Collapse(document.getElementById(id), {
                toggle: false,            
            })
            document.getElementById(id).addEventListener('show.bs.collapse', (e) => {
                onShow()
            })
            document.getElementById(id).addEventListener('hide.bs.collapse', (e) => {
                onHide() 
            })
        }
        
        load()
    }, [])

    return (
        <>
            <div className={"collapse " + className} id={id} {...parent  && {'data-bs-parent': '#' + parent}}>
                {children}
            </div>
        </>
    )
}

type CollapseLinkProps = {
    id: string
}

export function CollapseLink({
    id
}: CollapseLinkProps) {

    const ref = useRef<HTMLAnchorElement>(null)

    useEffect(() => {
        ref.current.addEventListener('click', (e) => {
            console.log('click')
            e.stopPropagation()
        })
    }, [])

    return (
        <>
            <a
                ref={ref}
                className="voirplus"
                data-bs-toggle="collapse"
                href={"#" + id}
                role="button"
                aria-expanded="false"
                aria-controls={id}>
            </a>
        </>
    )
}