'use client'

import React, { useRef, useEffect, ReactNode, FC, ComponentProps } from "react"
import { animateElement } from "@/services/theme"

export function Anim({
    children,
    tagName,
    ...props
}) {

    const refAnim = useRef<HTMLElement>(null)    

    useEffect(() => {
        const load = async () => {
          const ScrollMagic = (await import('scrollmagic')).default;
          const controller = new ScrollMagic.Controller()
          window['App'] = {}
          window['App']['SMController'] = controller
          
          if ('data-anim' in props) {
            new ScrollMagic.Scene({
                offset: "350px",
                triggerElement: refAnim.current,
                triggerHook: "onEnter",
                reverse: false,
              })
                .on("enter", function (event) {
                  animateElement(refAnim.current);
                })
                .addTo(controller)          
          }

          if ('data-anim-wrap' in props) {
            new ScrollMagic.Scene({
                offset: 100,
                triggerElement: refAnim.current,
                triggerHook: "onEnter",
                reverse: false,
              })
                .on("enter", function (event) {
                  const animChilds = refAnim.current?.querySelectorAll("[data-anim-child]") ?? [];
                  refAnim.current?.classList.add("animated");
                  animChilds.forEach((el) => animateElement(el));
                })
                .addTo(controller);
          }          
        }
        load()
      }, [])

    return React.createElement(
        tagName,
        {...props, ref: refAnim},
        children
    )    
}