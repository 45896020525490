'use client'

import { usePathname } from "next/navigation";
import { useEffect, useRef } from "react";


export default function HeaderMobileMenuButton() {

    const pathname = usePathname()
    const ref = useRef<HTMLButtonElement>()

    useEffect(() => {
        if (!ref.current) return
        if (document.querySelector('html').classList.contains('html-overflow-hidden')) {
            document.querySelector('html').classList.toggle('html-overflow-hidden')
        }
    }, [pathname])

    return (
        <button ref={ref} id='header-mobile-menu-button' className="d-flex items-center icon-menu text-inherit text-20" data-x-click="html, header, header-logo, header-mobile-icons, mobile-menu" onClick={(e) => {
            const attributes = (e.target as HTMLElement).getAttribute("data-x-click").split(", ");
            attributes.forEach((el) => {
                const target = document.querySelector(`[data-x=${el}]`);
                if (!target) return
                const toggleClass = target.getAttribute("data-x-toggle");
                target.classList.toggle(toggleClass);                    
            });
        }} />
    )
    
}