'use client'

import { useEffect } from "react"

export default function BackToTop() {

    useEffect(() => {
        let debounceTimeout;
        let body = document.querySelector("body");
        let scrollingElement = document.scrollingElement;

        setScrollClass()
        window.addEventListener("scroll", setScrollClass);
        window.addEventListener("resize", setScrollClass);

        function setScrollClass() {
            if (debounceTimeout) {
                window.cancelAnimationFrame(debounceTimeout);
            }

            debounceTimeout = window.requestAnimationFrame(function () {
                let scrollTop = scrollingElement.scrollTop;
                let scrollHeight = scrollingElement.scrollHeight;
                let innerHeight = window.innerHeight;
                let scrollBottom = scrollHeight - innerHeight - scrollTop;

                body.classList.toggle("at-top", scrollTop < 48);
                body.classList.toggle("at-bottom", scrollBottom < 48);
            });
        }
    }, [])

    return (
        <a className="back-to-top" href="#" onClick={(e) => {
            e.preventDefault()
            scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }
        }>
            <i className="fi fi-ss-angle-circle-up text-terracotta" />
        </a>
    )
}