'use client'

import { use, useEffect, useRef, ReactNode } from "react"
import { animateElement } from "@/services/theme"
import { Header as ThemeHeader } from "@/services/theme"
import { Anim } from "../ui/Anim"

type HeaderProps = {
  children: ReactNode
}

export default function Header({
  children = <></>
}: HeaderProps) {

  const refAnimHeader = useRef(null)

  useEffect(() => {
    const load = async () => {
      ThemeHeader.init()
    }
    load()
  }, [])

  useEffect(() => {
    document.querySelectorAll("ul.menu__nav li[data-menu]").forEach((li) => {
      li.addEventListener("mouseenter", (event) => {
        document.querySelectorAll(".mega-menu[data-menu]").forEach((menu) => {
          menu.classList.add("d-none");
        });
        document.querySelector('.mega-menu[data-menu="' + li.getAttribute("data-menu") + '"]')?.classList.remove("d-none");
      });
      li.addEventListener("mouseleave", (event) => {
        setTimeout(() => {
          if (Array.from(document.querySelectorAll('[data-menu="' + li.getAttribute("data-menu") + '"]')).map((li) => li.matches(":hover")).includes(true) === false) {
            document.querySelector('.mega-menu[data-menu="' + li.getAttribute("data-menu") + '"]')?.classList.add("d-none")
          }
        }, 10)
      })
    })
    document.querySelectorAll(".mega-menu[data-menu]").forEach((menu) => {
      menu.querySelectorAll('a:not(.voirplus)').forEach(a => {
        a.addEventListener("click", (event) => {
          document.querySelector('li[data-menu="' + menu.getAttribute("data-menu") + '"]')?.classList.remove('hover')
          menu.classList.add('d-none')
      })})
      menu.addEventListener("mouseenter", (event) => {
        setTimeout(() => {
          document.querySelector('li[data-menu="' + menu.getAttribute("data-menu") + '"]')?.classList.add('hover')
        })
      })
      menu.addEventListener("mouseleave", (event) => {
        setTimeout(() => {
          document.querySelector('li[data-menu="' + menu.getAttribute("data-menu") + '"]')?.classList.remove('hover')
          if (Array.from(document.querySelectorAll('[data-menu="' + menu.getAttribute("data-menu") + '"]')).map((li) => li.matches(":hover")).includes(true) === false) {
            menu.classList.add("d-none")            
          }
        }, 300)
      })
    })    
  }, [])

  useEffect(() => {
    initMenuLevel1()
    window.addEventListener('resize', initMenuLevel1)
  }, [])

  function initMenuLevel1() {
    const burgerMenu = document.querySelector('#header-mobile-menu-button') 
    if (burgerMenu === null) return
    if (burgerMenu.checkVisibility()) {
      document.querySelectorAll('a[data-barba][data-with-children]').forEach(elem => {
        if ((elem as HTMLAnchorElement).hasAttribute('href')) {
          elem.setAttribute('data-href', elem.getAttribute('href'))
          elem.removeAttribute('href')
        }
      })
    }
    else {
      document.querySelectorAll('a[data-barba][data-with-children]').forEach(elem => {
        if ((elem as HTMLAnchorElement).hasAttribute('data-href')) {
          elem.setAttribute('href', elem.getAttribute('data-href'))
          elem.removeAttribute('data-href')
        }
      })
    }
  }

  return (
    <>
      <div className="header-margin" />
      <header data-add-bg className="header js-header" data-x="header" data-x-toggle="is-menu-opened">
        <Anim tagName='div' data-anim="fade" className="header__container px-20">
          {children}  
        </Anim>                 
      </header>
    </>
  )
}